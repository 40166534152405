@import "./variables";

.u-font-bold {
    font-weight: 500;
}

.menu-bg {
    background-color: $menu-bg;
}

.primary-bg {
    background-color: $primaryColor;

    &.ant-avatar {
        background-color: $primaryColor;
    }
}

.primary-text {
    color: $primaryColor;
}

.white-color {
    color: #ffffff;
}

.font-size-l-3 {
    font-size: 26px;
}

.font-size-l-2 {
    font-size: 22px;
}

.font-size-l-1 {
    font-size: 18px;
}

.font-size-sm-2 {
    font-size: 11px;

    input.ant-calendar-range-picker-input {
        font-size: 11px;
    }
}

.font-size-m-1 {
    font-size: 12px;

    input.ant-calendar-range-picker-input,
    .ant-table {
        font-size: 13px;
    }
}

.font-size-m-2 {
    font-size: 14px;

    &.ant-checkbox-wrapper {
        &>span {
            font-size: 14px;
        }
    }
}

.font-size-m-3 {
    font-size: 16px;

    &.ant-checkbox-wrapper {
        &>span {
            font-size: 16px;
        }
    }
}

.font-size-m-4 {
    font-size: 18px;

    &.ant-checkbox-wrapper {
        &>span {
            font-size: 18px;
        }
    }
}

.font-size-sm-1 {
    font-size: 10px;
}

.font-size-header {
    font-size: 26px;
}

.text-align-center {
    text-align: center;
}

.spacer-xs {
    padding: 4px;
}

.spacer-s {
    padding: 8px;
}

.spacer-m {
    padding: 16px;
}

.spacer-l {
    padding: 24px;
}

.spacer-hl {
    padding: 0px 24px;
}

.spacer-vl {
    padding: 24px 0px;
}

.spacer-hm {
    padding: 0px 16px;
}

.spacer-vm {
    padding: 16px 0px;
}

.spacer-hs {
    padding: 0px 8px;
}

.spacer-vs {
    padding: 8px 0px;
}

.spacer-vxs {
    padding: 4px 0px;
}

.white-bg {
    background-color: $white-bg;
}

.grey-bg {
    background-color: $grey-bg;
}

.dark-grey-bg {
    background-color: $dark-grey-bg;
}

.font-text {
    color: $font-text;
}

.border-grey {
    border: 1px solid $grey-border;
}

.border-dark-grey {
    border: 1px solid $dark-grey-border
}

.border-radius-l {
    border-radius: 15px;

    & .ant-calendar-picker-input,
    .ant-input,
    .ant-select-selection {
        border-radius: 15px;
    }
}

.border-radius-xl {
    border-radius: 20px;

    & .ant-calendar-picker-input,
    .ant-input,
    .ant-select-selection {
        border-radius: 20px;
    }
}

.border-radius-m {
    border-radius: 10px;

    & .ant-calendar-picker-input,
    .ant-input,
    .ant-select-selection {
        border-radius: 10px;
    }
}

.border-radius-s {
    border-radius: 5px;

    & .ant-calendar-picker-input,
    .ant-input,
    .ant-select-selection {
        border-radius: 5px;
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.width-half {
    width: 50%;
}

.width-40 {
    width: 40%;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.flex-basis-50-gap-s {
    flex-basis: calc(50% - 5px)
}

.flex-basis-50-gap-l {
    flex-basis: calc(50% - 25px)
}

.flex-basis-60 {
    flex-basis: 60%;
}

.flex-basis-40 {
    flex-basis: 40%;
}

.text-decoration-underline {
    text-decoration: underline;
}

.ant-input-affix-wrapper .ant-input-suffix .anticon.anticon-close-circle.ant-input-clear-icon {
    font-size: 15px;
}

.navbar-bg,
.navbar-text {
    background-color: $navBarColor;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.text-ellipsis,
.text-ellipsis-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;

    &>span {
        width: 90%;
        display: inline-block;
    }
}

.margin-label {
    padding-left: 20px;
    padding-right: 20px;
}

.barcode-margin-bottom {
    margin-bottom: 10px;
}

.canvas-align {
    padding-left: 0;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: auto;
    display: block;
    width: 150px;
    justify-content: center;
}