.create-connection-header {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        border-top: 1px solid lightgrey;
        left: 0px;
    }
}

.create-connection-metabase-container {
    height: calc(100vh - 200px);
    overflow: auto;
}