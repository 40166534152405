@import "./variables";
@import "./utilities";
@import "./layout";
@import "./overwrite.ant";

.float-right {
  float: right;
}

.clearfix {
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not_allowed {
  cursor: not-allowed;
}

.cursor-disabled {
  pointer-events: none;
  cursor: default;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.load-share-custom {
  .ant-modal-header {
    padding: 24px 24px;
    font-size: 20px;
  }
}

.text-bold {
  font-weight: bold;
}

.text-semi-bold {
  font-weight: 500;
}

.ant-table-tbody {
  .ant-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.inline-block {
  display: inline-block;
}

button.ant-btn.filter-btn.filter-active {
  border: 1px solid #40a9ff;
}

.sendemail {
  width: 140px;
  margin-right: 10px;
  padding: 18px 10px;
}

.status-approve-reject {
  background-color: #eaeaea;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 3px 3px;
}

.status-approve-reject.REJECTED {
  color: #e6492d;
}

$align: stretch, center, space-around, space-between, start, space-evenly, end, flex-end, flex-start;

@each $item in $align {
  .justify-content-#{$item} {
    justify-content: $item;
    -webkit-justify-content: $item;
  }

  .align-items-#{$item} {
    align-items: $item;
    -webkit-align-items: $item;
  }
}

.flex-box {
  display: flex;
  display: -webkit-flex;
}

.flex-column {
  @extend .flex-box;
  flex-direction: column;
}

.flex-row {
  @extend .flex-box;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-flex-gap {
  gap: 0px !important;
}

.flex-gap-xs {
  gap: 2px;
}

.flex-gap-s {
  gap: 4px;
}

.flex-gap-m {
  gap: 8px;
}

.flex-gap-l {
  gap: 16px;
}

.flex-gap-xl {
  gap: 24px;
}

$flexGrow: 1, 2, 3, 4, 5, 6;

@each $item in $flexGrow {
  .flex-#{$item} {
    flex: $item;
  }
}

.badge-wrapper {
  .ant-badge {
    width: 100%;
  }
}

.ant-table-tag.ant-tag {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.detail-naviagtion {
  .detail-naviagtion-button {

    .ant-btn,
    .ant-btn:focus,
    .ant-btn:active,
    .ant-btn:hover {
      background: transparent;
      border: none;
      height: auto;
      color: #00000099;
    }
  }
}

.icon-size-header {
  font-size: 50px;
}

.icon-size-xl {
  font-size: 20px;
}

.icon-size-l {
  font-size: 15px;
}

.warning-tag,
.ant-tag.warning-tag {
  background-color: $warning-bg;
  border-color: $warning-border;
  color: $warning-text;
}

.error-tag,
.ant-tag.error-tag {
  background-color: $error-bg;
  border-color: $error-border;
  color: $error-text;
}

.success-tag,
.ant-tag.success-tag {
  background-color: $success-bg;
  border-color: $success-border;
  color: $success-text;
}

.info-tag,
.ant-tag.info-tag {
  background-color: $info-bg;
  border-color: $info-border;
  color: $info-text;
}

.primary-info-tag,
.ant-tag.primary-info-tag {
  background-color: $primary-info-bg;
  border-color: $primary-info-border;
  color: $primary-info-text;
}

.secondary-info-tag,
.ant-tag.secondary-info-tag {
  background-color: $secondary-info-bg;
  border-color: $secondary-info-border;
  color: $secondary-info-text;
}

.tertiary-info-tag,
.ant-tag.tertiary-info-tag {
  background-color: $tertiary-info-bg;
  border-color: $tertiary-info-border;
  color: $tertiary-info-text;
}

.info-color {
  color: $info-text;
}

.success-color {
  color: $success-text;

  .ant-alert-message {
    color: $success-text !important;
  }
}

.info-border {
  border-color: $info-border;
}

.vertical-borders {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.horizontal-borders {
  border-left-width: 0px;
  border-right-width: 0px;
}

.grey-text {
  color: $grey-text;
}

.light-grey-text {
  color: $light-grey-text;
}

.search-bar {
  @extend .flex-box;
  @extend .align-items-center;

  i {
    font-size: 24px;
    padding: 6px;
    @extend .border-grey;
    border-right: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  input {
    margin-left: -1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 300px;
    height: 38px;
  }

  input:active,
  input:focus {
    outline: none;
    box-shadow: none;
    border-color: $grey-border;
  }
}

.grey-shadow {
  box-shadow: 0px 0px 15px 2px #c7c7c7;
}

.display-none {
  display: none !important;
}

.black-border {
  border: 1px solid #d0d0d0;
}

.border-right-bottom {
  border-width: 0px 1px 0px 0px;
}

.print-waybill-booking-date {
  position: relative;
}

.print-waybill-booking-date::before {
  content: "";
  display: block;
  border-top: 1px solid #d0d0d0;
  position: absolute;
  width: calc(100% + 10px);
  left: -5px;
  top: -2px;
}

.break-all {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: break-word;
}

.color-red {
  color: #ff0000;
}

.page-wrapper {
  min-height: calc(100vh - 70px);
}

.popup-buttons-divider {
  border-top: 1px solid lightgrey;
  width: calc(100% + 48px);
  position: relative;
  left: -24px;
  margin-top: 20px;
  padding: 24px 10px 0px;
}

.ant-notification.ant-notification-bottomRight {
  .ant-notification-notice-message {
    word-break: break-all;
  }
}

.red-icon-wrapper svg {
  fill: $error-text;
}

.warning-color {
  color: $warning-text;
}

.error-color {
  color: $error-text;
}

.success-color {
  color: $success-text;
}

.primary-info-color {
  color: $primary-info-text;
}

.secondary-info-color {
  color: $secondary-info-text;
}

.tertiary-info-color {
  color: $tertiary-info-text;
}

.border-solid {
  border: 1px solid;
}

.border-bottom {
  border-width: 0px 0px 1px !important;
}

.legacy-sparkline {
  @extend .flex-box;
  @extend .flex-gap-xl;
  @extend .align-items-center;
  padding: 8px 0px 16px;

  & .legacy-sparkline-datacards {
    @extend .flex-4;
    @extend .flex-box;
    @extend .flex-gap-l;
    @extend .align-items-center;
  }

  & .legacy-sparkline-ctas {
    @extend .flex-1;
    @extend .flex-column;
    @extend .align-items-stretch;
    @extend .flex-gap-m;
    @extend .spacer-vs;
  }
}

.ant-input.error-field {
  border-color: #f5222d;

  &:hover {
    border-color: #f5222d;
  }

  &:focus {
    border-color: #f5222d;
    box-shadow: 0 0 0 2px rgb(245 34 45 / 20%);
  }
}

.success-bg {
  background-color: $success-bg;
}

.primary-info-bg {
  background-color: $primary-info-bg;
}

.error-bg {
  background-color: $error-bg;
}

.warning-bg {
  background-color: $warning-bg;
}

.warning-icon svg {
  fill: $warning-text;
}

.error-icon svg {
  fill: $error-text;
}

.curved-end {
  border-radius: 45px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}


.mb-16 {
  margin-bottom: 16px;
}

.m-20 {
  margin: 20px;
}

.error-border {
  border: 1px solid $error-border;
}

.full-width {
  width: 100%;
}

.highlight-on-hover-clickable:hover {
  cursor: pointer;
  border: 1px solid $action-button-btn-border !important;
  color: $action-button-text-color !important;
}

.spinning-loader {
  width: 18px;
  height: 18px;
  border: 2px solid #3b5a98;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.filter-modal-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.word-wrap-break-word {
  word-wrap: 'break-word'
}

.line-height-1 {
  line-height: 1.2;
}

.margin-0 {
  margin: 0px;
}

.metabase-subheader-container {
  padding: 10px;
  background-color: white;
}

.position-relative {
  position: relative;
}

.metabase-widget-v1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  height: 80%;
  cursor: pointer;
}

.margin-right-40 {
  margin-right: 40px;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-item-center {
  align-items: flex-start;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}


.margin-top-neg5 {
  margin-top: -5px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.waybill-link {
  color: rgba(59, 90, 152, 0.9);
  cursor: pointer;
}

.spin-container-height-100 {

  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    height: 100%;
  }
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.divider-container {
  padding-left: 20px;
  padding-right: 20px;

  .ant-divider {
    margin: 0px;
  }
}

.primary-div-container {
  background-color: #EFF2F5;
}

.p-20 {
  padding: 20px;
}