@import "../../../assets/styles/variables";

.address-text {
    white-space: break-spaces;
    display: inline-block;
}

.status-cell .ant-table-tag {
    margin-right: 0px;
}

.status-cell b {
    font-size: 12px;
}

.action-modal {
    .ant-modal-content {
        border-radius: 16px;
        top: 40px;
    }

    .ant-modal-footer {
        border: none;
    }

    .ant-modal-header {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
}

.assign-modal {
    .ant-modal-content {
        top: 40px;
    }
}

.circle-green {
    background-color: $primaryColor;
}

.circle-blue {
    background-color: $success-text-color;
}

.round-circle {
    padding: 2px 7px 1px 7px;
    font-size: 12px;
    border-radius: 50%;
    color: white;
}

.option-container {
    border-radius: 16px;
    border: 2px solid #EDEEF9;
    padding: 14px;
}

.vertical-divider {
    border-left: 2px dashed #ECEDF8;
    margin-left: 16px;
    height: 22px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-4 {
    margin-top: 4px;
}

.scan-awb-input {
    .ant-input-group-addon {
        background-color: $success-btn-bg;
    }
}

.option-container[disabled] {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.scan-res-card {
    margin-top: 16px;
    border-radius: 16px;
    box-shadow: -1px 0px 3px 1px rgba(240, 240, 240, 1);
}

.scan-res-card .ant-card-head {
    padding: 0px;
    margin: 0px 20px;
}

.card-subtitle {
    font-size: 13px;
}

.load-btn {
    box-shadow: 0px 0px 6px 5px $primary-btn-shadow-color !important;
}